.section {
  display: flex;
  justify-content: center;
  padding: 120px 170px 210px;
}

@media screen and (max-width: 1280px) {
  .section {
    padding: 100px 100px 210px;
  }
}
@media screen and (max-width: 1024px) {
  .section {
    padding: 60px 50px 210px;
  }
}
@media screen and (max-width: 768px) {
  .section {
    display: block;
  }
}
@media screen and (max-width: 480px) {
  .section {
    padding: 50px 35px;
  }
}

.section__title {
  flex-shrink: 0;
  width: 200px;
  margin-right: 100px;
  color: #007bff;
  text-transform: uppercase;
  text-align: right;
  font-weight: 700;
  letter-spacing: 2px;
}

@media screen and (max-width: 1024px) {
  .section__title {
    margin-right: 50px;
  }
}
@media screen and (max-width: 850px) {
  .section__title {
    width: 150px;
  }
}
@media screen and (max-width: 768px) {
  .section__title {
    text-align: left;
    width: auto;
  }
}

.section__content {
  font-weight: 300;
  font-size: 1rem;
  width: 100%;
  max-width: 650px;
}

@media screen and (max-width: 768px) {
  .section__content {
    padding-top: 30px;
    padding-left: 50px;
  }
}
@media screen and (max-width: 480px) {
  .section__content {
    padding-left: 30px;
  }
}

.skills {
  display: flex;
  justify-content: space-around;
}

.skills__category__label {
  font-weight: 700;
  color: #36363c;
  text-transform: uppercase;
  padding-bottom: 5px;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.skills__category__item {
  margin: 5px 0;
  font-size: 1rem;
}

@media screen and (max-width: 630px) {
  .skills {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 630px) {
  .skills .skills__category {
    width: 47%;
    margin-right: 5px;
    margin-bottom: 25px;
  }
}
