.social__icons {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 150px; /* use 200 with linkedin */
  top: 30px;
  right: 10px;
}

.email {
  background-image: url(./../../imgs/social/email.png);
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  height: 30px;
  width: 37px;
  transition: all 250ms ease-in-out;
}

.github {
  background-image: url(./../../imgs/social/github.png);
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  height: 30px;
  width: 35px;
  margin-left: 12px;
  transition: all 250ms ease-in-out;
}

.linkedin {
  background-image: url(./../../imgs/social/linkedin.png);
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  height: 30px;
  width: 35px;
  margin-left: 8px;
  transition: all 250ms ease-in-out;
}

@media screen and (min-width: 768px) {
  .email:hover,
  .github:hover,
  .linkedin:hover {
    transform: translateY(-5px);
  }
}

@media screen and (max-width: 768px) {
  .social__icons {
    top: 20px;
    right: 10px;
  }
  .github,
  .linkedin {
    height: 25px;
    width: 30px;
  }
  .email {
    height: 25px;
    width: 32px;
  }
}

@media screen and (max-width: 375px) {
  .social__icons {
    width: 100%;
    top: 20px;
  }
  .github,
  .linkedin {
    height: 25px;
    width: 30px;
  }
  .email {
    height: 25px;
    width: 32px;
  }
}
