.footer {
  padding: 30px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

@media screen and (max-width: 630px) {
  .footer {
    display: block;
  }
}
@media screen and (max-width: 480px) {
  .footer {
    padding: 50px 30px;
  }
}

.footer__copyright {
  font-weight: 500;
  width: 200px;
  font-size: 0.8rem;
}
@media screen and (max-width: 630px) {
  .footer__copyright {
    width: auto;
    text-align: center;
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 630px) {
  .footer__copyright .bottom,
  .footer__copyright .top {
    display: inline-block;
  }
}
@media screen and (max-width: 480px) {
  .footer__copyright .bottom,
  .footer__copyright .top {
    display: block;
  }
}
.footer__copyright .emoji {
  width: 20px;
  height: 20px;
  margin: 0 3px;
  vertical-align: text-bottom;
}
@media screen and (max-width: 630px) {
  .footer__links {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

.footer__links a {
  position: relative;
  display: inline-block;
  color: #007bff;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 15px;
  outline: 0;
  text-decoration: none;
  letter-spacing: 1.5px;
  font-size: 0.9rem;
}
@media screen and (max-width: 1024px) {
  .footer__links a {
    margin: 0 10px;
  }
}
.footer__links a:after {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 1px;
  background: #007bff;
  content: '';
  opacity: 0;
  -webkit-transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  transition: height 0.3s, opacity 0.3s, -webkit-transform 0.3s;
  transition: height 0.3s, opacity 0.3s, transform 0.3s;
  transition: height 0.3s, opacity 0.3s, transform 0.3s, -webkit-transform 0.3s;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
@media screen and (max-width: 850px) {
  .footer__links a:after {
    display: none;
  }
}
.footer__links a:focus:after,
.footer__links a:hover:after {
  height: 2px;
  border-radius: 3px;
  opacity: 1;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.footer__links a .text {
  font-size: 0.9rem;
  display: inline;
  letter-spacing: 1px;
}
@media screen and (max-width: 850px) {
  .footer__links a .text {
    display: none;
  }
}
.footer__links img {
  display: none;
  width: 22px;
}
@media screen and (max-width: 850px) {
  .footer__links img {
    display: inline-block;
  }
}

@media screen and (max-width: 630px) {
  .footer__links {
    display: flex;
    flex-wrap: wrap;
  }
}