.top_button {
  transition: all 300ms ease-in-out;
  background: 0 0;
  border: none;
  outline: 0;
  width: 40px;
  position: fixed;
  bottom: 25px;
  right: 10px;
  cursor: pointer;
}

@media screen and (max-width: 1024px) {
  .top_button {
    bottom: 20px;
    right: 7px;
    width: 35px;
  }
}
@media screen and (max-width: 480px) {
  .top_button {
    bottom: 15px;
    right: 3px;
  }
}
.top_button:focus,
.top_button:hover {
  transform: translateY(-10px);
}

.top_button img {
  width: 100%;
}
