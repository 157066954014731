.section {
  display: flex;
  justify-content: center;
  padding: 100px 170px;
}

@media screen and (max-width: 1280px) {
  .section {
    padding: 100px;
  }
}
@media screen and (max-width: 1024px) {
  .section {
    padding: 50px;
  }
}
@media screen and (max-width: 768px) {
  .section {
    display: block;
  }
}
@media screen and (max-width: 480px) {
  .section {
    padding: 50px 35px;
  }
}

.section__title {
  flex-shrink: 0;
  width: 200px;
  margin-right: 100px;
  color: #007bff;
  text-transform: uppercase;
  text-align: right;
  font-weight: 700;
  letter-spacing: 2px;
}

@media screen and (max-width: 1024px) {
  .section__title {
    margin-right: 50px;
  }
}
@media screen and (max-width: 850px) {
  .section__title {
    width: 150px;
  }
}
@media screen and (max-width: 768px) {
  .section__title {
    text-align: left;
    width: auto;
    margin-bottom: 20px;
  }
}

.section__content {
  font-weight: 300;
  font-size: 1rem;
  width: 100%;
  max-width: 650px;
}

@media screen and (max-width: 768px) {
  .section__content {
    padding-top: 30px;
    padding-left: 50px;
  }
}
@media screen and (max-width: 480px) {
  .section__content {
    padding-left: 30px;
  }
}

.project {
  display: flex;
  margin-bottom: 30px;
  margin-left: -5%;
  width: 125%;
}

.project__img {
  max-width: 700px;
  min-width: 200px;
  overflow: visible;
  margin: 0;
}

.project__img img {
  width: 100%;
  max-width: 100%;
  min-height: 75%;
  vertical-align: bottom;
}

.project__img img.boloscout {
  padding: 0 220px;
}

.project__caption {
  width: 125px;
  flex-shrink: 0;
  margin-left: 50px;
}

@media screen and (max-width: 768px) {
  .project .project__caption {
    width: 100%;
    margin-top: 25px;
    margin-left: 0;
  }
}

.project__name {
  font-weight: 700;
  color: #36363c;
  padding-bottom: 2px;
}

.project__details {
  margin: 8px 0;
}

.project__details__links {
  padding: 10px 0;
}

.project__details ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.project__links__title {
  font-weight: 700;
  color: #36363c;
  padding: 10px 0 2px 0;
}

.project__links {
  margin: 5px 0;
  font-size: 1rem;
}

.project__links a {
  color: #007bff;
  font-weight: 500;
  text-decoration: none;
}

.project__links a:hover {
  border-bottom: 2px solid #007bff;
}

.project__stack {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom: 150px;
  margin-left: -5%;
  width: 125%;
}

.project__stack ul {
  display: flex;
}

.project__stack li {
  margin: 0 5px;
  display: inline-block;
  font-family: Inconsolata, monospace;
  font-weight: 700;
  padding: 3px 9px;
  border-radius: 3px;
  color: #007bff;
  border: 1px solid #007bff;
}

@media screen and (max-width: 768px) {
  .project__stack {
    width: 100%;
  }
  .project__stack ul {
    display: flex;
    flex-wrap: wrap;
    margin-right: 10px;
  }
  .project__stack li {
    margin-top: 10px;
  }
}

@media screen and (max-width: 1280px) {
  .project {
    width: 115%;
  }
}
@media screen and (max-width: 1024px) {
  .project {
    width: 105%;
  }
}
@media screen and (max-width: 768px) {
  .project {
    width: 100%;
    display: block;
    margin-bottom: 30px;
  }
}
