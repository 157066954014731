.intro {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 120px 100px;
  height: 100vh;
  max-width: 1440px;
  margin: 0 auto;
}

.intro__hello {
  display: block;
  font-weight: 300;
  font-size: 2.5rem;
  margin: 20px 0;
}

@media screen and (max-width: 630px) {
  .intro {
    padding: 120px 70px;
  }
}
@media screen and (max-width: 550px) {
  .intro {
    padding: 120px 50px;
  }
}
@media screen and (max-width: 480px) {
  .intro {
    padding: 70px 50px;
  }
}

.wave {
  background-image: url(./../../imgs/emojis/wave.png);
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
  animation-duration: 1000ms;
  animation-fill-mode: both;
}

.wave:hover {
  animation-name: wave;
}

@-webkit-keyframes wave {
  from {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-20%, 0, 0) rotate3d(0, 0, 1, -10deg);
    transform: translate3d(-20%, 0, 0) rotate3d(0, 0, 1, -10deg);
  }
  30% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 7deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 7deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -10deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 5deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 5deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes wave {
  from {
    -webkit-transform: none;
    transform: none;
  }
  15% {
    -webkit-transform: translate3d(-20%, 0, 0) rotate3d(0, 0, 1, -10deg);
    transform: translate3d(-20%, 0, 0) rotate3d(0, 0, 1, -10deg);
  }
  30% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 7deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 7deg);
  }
  45% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -10deg);
    transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -10deg);
  }
  60% {
    -webkit-transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 5deg);
    transform: translate3d(10%, 0, 0) rotate3d(0, 0, 1, 5deg);
  }
  75% {
    -webkit-transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -2deg);
    transform: translate3d(-5%, 0, 0) rotate3d(0, 0, 1, -2deg);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

.intro__about {
  font-weight: 300;
  max-width: 700px;
  font-size: 2.5rem;
  margin: 20px 0;
}

.intro__about__name {
  font-weight: 500;
}

.intro__resume {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 20px 0;
}

.intro__resume__text {
  font-weight: 400;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
}

.pointright {
  background-image: url(./../../imgs/emojis/pointright.png);
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  height: 35px;
  width: 35px;
  margin-right: 25px;
  animation-duration: 800ms;
  animation-fill-mode: both;
}

.intro__resume__text > a {
  transition: all 300ms ease-in-out;
  font-weight: 400;
  color: inherit;
  text-decoration: none;
  display: inline-block;
  padding: 3px 3px;
  text-decoration: none;
  box-shadow: inset 0 -3px 0 #007bff;
}

.intro__resume__text > a:hover {
  box-shadow: inset 0 -33px 0 0 #007bff;
  color: #fff;
}

.animate__point {
  display: flex;
}

.animate__point:hover > .pointright {
  animation-name: point;
  cursor: pointer;
}

@keyframes point {
  from {
    -webkit-transform: none;
    transform: none;
  }
  50% {
    -webkit-transform: translate3d(-15%, 0, 0) rotate3d(0, 0, 1, -10deg);
    transform: translateX(50%);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}

@media screen and (max-width: 1024px) {
  .intro__hello,
  .intro__about,
  .intro__contact {
    font-size: 2.25rem;
  }
}
@media screen and (max-width: 768px) {
  .intro__hello,
  .intro__about,
  .intro__contact {
    font-size: 2rem;
  }
}
@media screen and (max-width: 550px) {
  .intro__hello,
  .intro__about,
  .intro__contact {
    font-size: 1.75rem;
  }
}
@media screen and (max-width: 360px) {
  .intro__hello,
  .intro__about,
  .intro__contact {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 330px) {
  .intro__hello,
  .intro__about,
  .intro__contact {
    font-size: 1.4rem;
  }
}

@media screen and (max-width: 1024px) {
  .wave,
  .pointright,
  .email,
  .github,
  .linkedin {
    width: 35px;
    height: 35px;
  }
}
@media screen and (max-width: 768px) {
  .wave,
  .pointright,
  .email,
  .github,
  .linkedin {
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width: 360px) {
  .wave,
  .pointright,
  .email,
  .github,
  .linkedin {
    width: 25px;
    height: 25px;
  }
}

/* .pointright {
  background-image: url(./../../imgs/emojis/pointright.png);
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 10px;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.email {
  background-image: url(./../../imgs/social/email.svg);
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 40px;
  cursor: pointer;
  width: 40px;
  height: 40px;
}

.linkedin {
  background-image: url(./../../imgs/social/linkedin.svg);
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 20px;
  cursor: pointer;
  width: 40px;
  height: 35px;
}

.github {
  background-image: url(./../../imgs/social/github.svg);
  display: inline-block;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: 20px;
  cursor: pointer;
  width: 40px;
  height: 35px;
} */
